import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import payment from './payment'
import gbp from './gbp'

export default new Vuex.Store({
  modules: {
    payment,
    gbp
  }
})
