export const SET_ITEM_ID = 'setItemId';
export const GET_ITEM_INFO = 'getItemInfo';

export const GENERATE_TRANSACTION = 'generateTransaction';
export const GENERATE_TRANSACTION_GBP = 'generateTransactionGBP';


export const CHECK_TRANSACTION_STATUS = 'checkTransactionStatus';

export const SET_USER_INFO = 'setUserInfo';
export const GET_USER_INFO = 'getUserInfo';

export const GET_REDEEM_CODE = 'getRedeemCode';
export const SET_RDP_USER_ID = 'setRadarsPointUserId';

export const GET_TOKEN_GBP = 'getTokenGBP';
export const CREATE_FULL_PAYMENT = 'createFullPayment';

export const CHECK_PERMISSION = 'checkPermission';

export const GET_CARD_TOKEN = 'getCardToken';
export const SET_CARD_TOKEN = 'setCardToken';
