import {
    CHECK_PERMISSION,
    CHECK_TRANSACTION_STATUS,
    GENERATE_TRANSACTION, GENERATE_TRANSACTION_GBP,
    GET_ITEM_INFO,
    GET_REDEEM_CODE, GET_USER_INFO, SET_RDP_USER_ID,
    SET_USER_INFO
} from "./actions.type";
import {paymentService} from "../service/payment";
import Vue from 'vue'

function initState() {
    return {
        unique_id: null,
        qr: null,
        transactionId: null,
        verifyCode: null,
        totalPrice: 0,
        subTxn: [],
        itemInfo: {},
        userInfo: {},
        rpId: null,
        eUid: null,
        originalName: null,
        qty: 1
    }
}

const state = initState();

const actions = {

    async [GET_ITEM_INFO]({commit}, id) {
        let response = await paymentService.getItemById(id);
        if (response.status === 'yes') {
            commit(GET_ITEM_INFO, response);
        }
        return response;
    },


    async [GENERATE_TRANSACTION]({commit}, data) {
        let response = await paymentService.generateTransaction(data);
        if (response.status === 'yes') {
            commit(GENERATE_TRANSACTION, response);
        }
        return response;
    },

    async [GENERATE_TRANSACTION_GBP]({commit}, data) {
        let response = await paymentService.generateTransactionGBP(data);
        if (response.status === 'yes') {
            commit(GENERATE_TRANSACTION, response);
        }
        return response;
    },

    async [CHECK_TRANSACTION_STATUS]({commit}, data) {
        let response = await paymentService.checkTransactionStatus(data);
        if (response.status === 'yes') {
            if (response.result.status === 'PAID' && response.result.vf) {
                commit(CHECK_TRANSACTION_STATUS, response.result);
            }
        }
        return response;
    },

    async [GET_USER_INFO]({commit}) {
        let user = Vue.$cookies.get('user') || null;
        if (user) {
            commit(SET_USER_INFO, user);
        }
    },

    async [SET_USER_INFO]({commit}, data) {
        commit(SET_USER_INFO, data);
    },

    async [GET_REDEEM_CODE]({commit}, data) {
        return await paymentService.getRedeemCode(data);
    },

    async [CHECK_PERMISSION]({commit}, data) {
        return await paymentService.checkPermission(data);
    },


    async [SET_RDP_USER_ID]({commit}, data) {
        commit(SET_RDP_USER_ID, data);
    },
};

const mutations = {
    [GENERATE_TRANSACTION](state, result) {
        state.qr = result.qr;
        state.transactionId = result.result;
    },
    [GET_ITEM_INFO](state, result) {
        state.itemInfo = result.result;
    },
    [SET_USER_INFO](state, user) {
        state.userInfo = user;
    },
    [CHECK_TRANSACTION_STATUS](state, result) {
        state.verifyCode = result.vf;
    },
    [SET_RDP_USER_ID](state, data) {
        state.rpId = data.rpId || null;
        state.eUid = data.euid || null;
        state.originalName = data.originName || null;
    },
};

const getters = {};

export default {
    state,
    actions,
    mutations,
    getters
};
