<template>
  <div>
    <slideout-panel></slideout-panel>
    <router-view/>
    <cookie-law theme="base">
      <div slot-scope="props">
        <div class="container w-100">
          <div class="row align-items-center">
            <div class="col mb-3 mb-sm-0">
              เราใช้คุกกี้ (cookie) เพื่อส่งมอบประสบการณ์ที่ดียิ่งขึ้นในการเยี่ยมชมเว็บไซต์
              รวมถึงนำเสนอสิทธิประโยชน์ที่ตรงกับความต้องการของท่าน โดยขอให้มั่นใจว่าข้อมูลจะได้รับการดูแลเป็นอย่างดี
              คลิกดูรายละเอียด <a class="text-primary pointer" target="_blank"
                                  href="https://radarspoint.com/pdpa/privacy-policy"> นโยบายคุกกี้ของเรา </a>
            </div>
            <div class="col-12 col-sm-auto">
              <button class="btn btn-primary mr-2" @click="props.accept">ยอมรับ</button>
              <button class="btn btn-outline-primary" @click="props.close">ปิด</button>
            </div>
          </div>
        </div>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: {CookieLaw}
}
</script>

<style scoped lang="scss">
.Cookie {
  justify-content: center !important;
}
</style>
